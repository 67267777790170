import React, { useState, useEffect } from "react";
import "../css/FirstSection.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";

export default function FirstSection() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 809);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 809);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logos = [
    { src: "logo/가톨릭관동대.png", alt: "가톨릭관동대학교 국제성모병원" },
    { src: "logo/클리오닉.png", alt: "CLIONIC 클리오닉 이비인후과의원" },
    { src: "logo/동아일보.png", alt: "동아일보" },
    { src: "logo/옥스포드.png", alt: "옥스포드" },
    { src: "logo/요기보.png", alt: "요기보" },
    { src: "logo/일상임상심리연구소.png", alt: "일상임상심리연구소" },
    { src: "logo/중앙.png", alt: "중앙" },
    { src: "logo/지식채널e.png", alt: "지식채널e" },
    { src: "logo/테라젠헬스.png", alt: "테라젠헬스" },
    { src: "logo/현대.png", alt: "현대" },
    { src: "logo/sbs.png", alt: "SBS" },
    { src: "logo/sk브로드밴드.png", alt: "sk브로드밴드" },
    { src: "logo/snuh.png", alt: "SNUH" },
    { src: "logo/tv조선.png", alt: "TV조선" },
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{
        ease: "easeInOut",
        duration: 2,
        y: { duration: 1 },
      }}
    >
      <div>
        <header>
          <img
            src="https://framerusercontent.com/images/I7cBPM8vqb6wCvDy3xhT8BouvMU.png?scale-down-to=512"
            alt="S-OMNI logo"
            className="logo"
          />
        </header>

        <section className="first-section">
          <h1>이제 안심하세요.</h1>
          <h1>
            당신은 <span className="highlight">숙면</span>할 수 있어요.
          </h1>
          <p>
            {isMobile ? (
              <>
                에스옴니가 당신의
                <br /> 숙면과 마음 건강을 케어합니다.
              </>
            ) : (
              <>에스옴니가 당신의 숙면과 마음 건강을 케어합니다.</>
            )}
          </p>
        </section>
        <div className="slider-container">
          <div className="slider">
            {logos.map((logo, index) => (
              <img key={index} src={logo.src} alt={logo.alt} />
            ))}
            {logos.map((logo, index) => (
              <img key={index + logos.length} src={logo.src} alt={logo.alt} />
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
