import React, { useState, useEffect } from "react";
import "../css/FourthSection.css";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  CardActionArea,
  CardActions,
} from "@mui/material";
import apiUrl from "../config/apiUrl";
import { motion } from "framer-motion";

export default function FourthSection() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/website_article/test`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setData(data.result); // 이 부분을 확인하세요. 배열을 바로 설정합니다.
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{
        ease: "easeInOut",
        duration: 2,
        y: { duration: 1 },
      }}
    >
      <section className="fourth-section">
        <h2 className="fourth-h2">대표 보도 기사</h2>
        <Grid container spacing={2} className="grid-container">
          {data && data.length > 0 ? (
            data.map((datacard) => (
              <Grid item xs={12} sm={6} md={6} lg={4} key={datacard.id}>
                <Card className="card">
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="197"
                      image={datacard.image}
                      alt={datacard.title}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="p"
                        className="fourth-typograpy"
                      >
                        <strong>{datacard.title}</strong>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{ textAlign: "left" }}
                      >
                        {datacard.from}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button onClick={() => handleClick(datacard.url)}>
                      바로가기 →
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              데이터를 불러오는 중입니다...
            </Typography>
          )}
        </Grid>
      </section>
    </motion.div>
  );
}
