import React, { useState, useEffect } from "react";
import "../css/ThirdSection.css";
import { motion } from "framer-motion";

export default function ThirdSection() {
  // 상태와 화면 크기 감지를 위한 훅
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 810 || window.innerWidth > 1099
  );
  const [isMobile2, setIsMobile2] = useState(window.innerWidth < 810);

  // 화면 크기 변경에 따른 상태 업데이트
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 810 || window.innerWidth > 1099);
      setIsMobile2(window.innerWidth < 810);
    };

    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{
        ease: "easeInOut",
        duration: 2,
        y: { duration: 1 },
      }}
    >
      <section className="third-section">
        <div className="third-content">
          <div className="third-content-left">
            <h2 className="third-content-left-h2">
              {isMobile ? (
                <>에스옴니(S-OMNI)</>
              ) : (
                <>
                  에스옴니(S-OMNI) <span>솔루션이 궁금해요</span>
                </>
              )}
            </h2>
            <p className="third-content-left-p1">
              {isMobile ? <>솔루션이 궁금해요</> : <></>}
            </p>
          </div>
          <div className="third-content-right">
            <h2>
              에스옴니의 수면 솔루션은 <span>무엇이 다른가요?</span>
            </h2>
            <p>
              일반적으로, 수면 환경(빛, 온도, 습도 등)이나 침구류(베개, 매트리스
              등),
              <br /> 영양제(감태추출물, 가바, 마그네슘 등)이 수면을 위한
              솔루션으로 알려져 있어요.
              <br />
              하지만 현대인 수면 문제는 대부분 <strong>심리적 스트레스</strong>
              와<strong> 불균형한 생활방식</strong>에서 비롯된다는 사실에
              착안하여, 에스옴니는 보다 근본적인 맞춤 솔루션을 개발하는데
              집중해왔어요.
              <br /> 바로, <strong>4C</strong>(코칭-콘텐츠-케어-커뮤니티)
              <strong>기반 토탈 수면 솔루션</strong>이죠.
            </p>
            <br />
            <h2>
              {isMobile2 ? (
                <>
                  수면 솔루션은 <span>정말로 효과가 있나요?</span>
                </>
              ) : (
                <>
                  에스옴니의 수면 솔루션은 <span>정말로 효과가 있나요?</span>
                </>
              )}
            </h2>
            <p>
              <strong>국내 유수 의학계 및 심리학계 파트너들</strong>과 함께
              효과적이고 안전한 수면 솔루션을 개발해왔어요.{" "}
              <strong>
                2023년 세계수면학회, 대한수면학회, 대한수면연구학회
              </strong>
              에서 발표한 연구 결과들에 따르면, 에스옴니의 수면 솔루션이{" "}
              <strong>임상적으로 수면 개선에 효과가 있음</strong>을
              확인하였어요. <small>(2024년 SCI 국제 저널 4편 게재 예정)</small>
            </p>
            <br />
            <h2>
              {isMobile2 ? (
                <>
                  수면 솔루션을 <span>어떤 분들에게 추천하나요?</span>
                </>
              ) : (
                <>
                  에스옴니의 수면 솔루션을{" "}
                  <span>어떤 분들에게 추천하나요?</span>
                </>
              )}
            </h2>
            <p>
              1. 숙면을 통해 <strong>스트레스 케어, 건강 증진 및 예방</strong>이
              필요한 분<br /> → 직장인, 학생, 주부, 교대근무자
              <br />
              <br />
              2. 숙면을 통해{" "}
              <strong>생산성 향상, 피로 개선 및 멘탈 관리</strong>
              가 필요한 분<br />→ 수험생, 자영업자, 운동선수
            </p>
            <br />
            <h2>
              {isMobile2 ? (
                <>
                  수면 솔루션은 <span>어떻게 체험할 수 있나요?</span>
                </>
              ) : (
                <>
                  에스옴니의 수면 솔루션은 <span>어떻게 체험할 수 있나요?</span>
                </>
              )}
            </h2>
            <p>
              공식 이메일 <strong>info@s-omni.com</strong>로 문의를 보내주시면,
              <br />
              영업일 기준 <strong>3-5일 이내</strong>에 정성껏 회신 드릴게요!
            </p>
          </div>
        </div>
      </section>
    </motion.div>
  );
}
