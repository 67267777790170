import React, { useState, useEffect } from "react";
import "../css/Footer.css";
import { motion } from "framer-motion";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 809);

  // 화면 크기 변경에 따른 상태 업데이트
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 809);
    };

    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer className="footer">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{
          ease: "easeInOut",
          duration: 2,
          y: { duration: 1 },
        }}
      >
        <div className="footer-content">
          <div className="footer-left">
            <div className="footer-logo">
              <img
                src="https://framerusercontent.com/images/xg8MuEJGQssE9gkdvOlowFlYdY.png?scale-down-to=512"
                alt="S-OMNI"
              />
            </div>
            <p className="footer-left-p">
              {isMobile ? (
                <>
                  <strong>대한민국 No.1 토탈 수면 솔루션, (주)에스옴니</strong>
                  <br />
                  <br /> 사업자 등록번호 : 231-86-02585 | 대표 : 유재성
                  <br />
                  서울특별시 중구 서애로5길 12-20, 4층(필동 3가, 성운빌딩)
                </>
              ) : (
                <>
                  <strong>(주)에스옴니</strong>
                  <br /> 사업자 등록번호 : 231-86-02585 | 대표 : 유재성
                  <br />
                  서울특별시 중구 서애로5길 12-20, 4층(필동 3가, 성운빌딩)
                </>
              )}
            </p>
          </div>
          <div className="footer-right">
            <p className="slogan">
              {isMobile ? <></> : <>대한민국 No.1 토탈 수면 솔루션</>}
            </p>
            <p className="copyright">
              Copyright 2023. S-OMNI. all rights reserved.
            </p>
          </div>
        </div>
      </motion.div>
    </footer>
  );
};

export default Footer;
