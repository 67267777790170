import React from "react";
import "../css/FifthSection.css";
import { motion } from "framer-motion";

const bookStoreUrl = "https://product.kyobobook.co.kr/detail/S000211513169";
const goToBookStore = () => {
  window.open(bookStoreUrl, "_blank");
};

export default function FifthSection() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{
        ease: "easeInOut",
        duration: 2,
        y: { duration: 1 },
      }}
    >
      <section className="fifth-section">
        <div className="fifth-section-container">
          <div className="fifth-section-image">
            {/* 책 이미지를 여기에 삽입 */}
            <img
              src="https://framerusercontent.com/images/Q9gDmMwJDXGgdrQL2ltE6vuVMX4.png?scale-down-to=2048"
              alt="오늘 밤 당신의 도착지는 숙면입니다"
            />
          </div>
          <div className="fifth-section-content">
            <h2 className="fifth-section-h2">
              <span className="fifth-section-span">베스트셀러</span> MD의 선택
            </h2>
            <p>
              <strong>국내 최초</strong> 셀프헬프 숙면 안내서!
              <br /> <strong>200여 편</strong>의 논문 근거 기반,
              <br /> <strong>32가지</strong> 유형별 <strong>100여가지</strong>{" "}
              솔루션 수록
              <br />
              <br /> <strong>한국 뇌연구원장, 수면전문의</strong>들이 추천한 책
              <br />
              <br />
              2주 만에 건강 분야 <strong>베스트셀러</strong>
              <br />{" "}
              <span className="fifth-section-span2">
                (네이버, 교보문고, 예스24, 알라딘, 영풍문고)
              </span>
              <br />
              <br /> AI 오디오북 제작사 ‘오디언’으로부터
              <br /> 전액 지원을 받아 <strong>오디오북 개발 시작</strong>
            </p>
            <button className="cta-button" onClick={goToBookStore}>
              북스토어 바로가기
            </button>
          </div>
        </div>
      </section>
    </motion.div>
  );
}
