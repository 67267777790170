import React from 'react';
import './App.css';
import FirstSection from './section/FirstSection';
import SecondSection from './section/SecondSection';
import ThirdSection from './section/ThirdSection';
import FourthSection from './section/FourthSection';
import FifthSection from './section/FifthSection';
import Footer from './section/Footer';


function App() {
  return (
    <div className="App">
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <Footer />
    </div>
  );
}

export default App;