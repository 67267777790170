import React, { useState, useEffect } from "react";
import second_img from "../img/secondsection_img.png";
import third_img from "../img/thirdsection_img.png";
import fourth_img from "../img/fourthsection_img.png";
import fifth_img from "../img/fifthsection_img.png";
import "../css/SecondSection.css";
import { motion } from "framer-motion";

const googleStoreUrl =
  "https://play.google.com/store/apps/details?id=com.somnia&pcampaignid=web_share";
const AppStoreUrl =
  "https://apps.apple.com/kr/app/%EC%86%9C%EB%8B%88%EC%95%84-%EC%B5%9C%EC%83%81%EC%9D%98-%EC%88%98%EB%A9%B4-%EC%9D%8C%EC%95%85-%EB%AA%85%EC%83%81-%EA%B8%B0%EC%83%81-%EC%95%8C%EB%9E%8C/id6470781599";

const goToGoogle = () => {
  window.open(googleStoreUrl, "_blank");
};
const goToAppstore = () => {
  window.open(AppStoreUrl, "_blank");
};
const youtubeUrl = "https://www.youtube.com/@DeepSleepGuide";

const goYoutube = () => {
  window.open(youtubeUrl, "_blank");
};

export default function SecondSection() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 809);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1099);

  // 화면 크기 변경에 따른 상태 업데이트
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 809);
      setIsTablet(window.innerWidth <= 1099);
    };

    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="second-section">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{
          ease: "easeInOut",
          duration: 2,
          y: { duration: 1 },
        }}
      >
        {/* first-container */}
        <div className="container">
          {/* <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{
            ease: "easeInOut",
            duration: 2,
            y: { duration: 1 },
          }}
        > */}
          <div className="content">
            <h2>
              <strong>근본적인 원인 발견,</strong>
              <br />
              <strong>
                <span>맞춤 수면 코칭</span>
              </strong>
              을 통해
              <br />
              <strong>최상의 뇌 컨디션</strong>을 회복하세요.
            </h2>
            <p>
              {isMobile ? (
                <>
                  <strong>54명</strong> 대상 맞춤 수면코칭 후, 불면증 심각 수준
                </>
              ) : (
                <>총 세 그룹(54명) 대상 맞춤 수면코칭 결과,</>
              )}
            </p>
            <p>
              {isMobile ? (
                <>
                  <strong>평균 44.3%</strong> 개선 효과 확인 (P&lt;0.001)
                </>
              ) : (
                <>
                  불면증 심각도(ISI) <strong>평균 44.3%</strong> 개선 효과 확인
                  (P&lt;0.001)
                </>
              )}
            </p>
            <p className="star-p">
              {isMobile ? (
                <></>
              ) : (
                <>
                  *분당서울대병원 정신과 전문의와 함께 대한수면학회 학술포스터
                  게재
                </>
              )}
            </p>
            <ul>
              <li>
                <strong>국내 1호 미국기관인증</strong> 성인 수면코치,{" "}
                <strong>하버드의대</strong> 부속
                <br />
                코칭연구소 필드우드 코치가 직접 안내하는 맞춤 프로그램
              </li>
              <li>
                H백화점 VIP고객 대상 - 전 항목 100% <strong>"만족"</strong>{" "}
                달성,
                <br />
                재참여 의사<strong>(9.6점/10점),</strong> 추천 의향
                <strong>(9.4점/10점)</strong>
              </li>
            </ul>
          </div>
          <div>
            <img src={second_img} alt="Sleep coaching illustration" />
          </div>
          {/* </motion.div> */}
        </div>
        {/* first-container */}

        {/* second-container */}
        <div className="container">
          {/* <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{
            ease: "easeInOut",
            duration: 2,
            y: { duration: 1 },
          }}
        > */}
          <div>
            <img src={third_img} alt="Sleep coaching illustration" />
          </div>
          <div className="content">
            <h2>
              <strong>대한민국 No.1,</strong>
              <br />
              <strong>
                <span>수면 전문 콘텐츠</span>
              </strong>
              를 통해
              <br />
              <strong>최고의 숙면</strong>을 경험하세요.
            </h2>
            <p>
              국내 최초 수면 전문의의 <strong>메디컬 리뷰</strong> 완료,
            </p>
            <p>
              {isMobile ? (
                <>
                  <strong>1/2/3차 병원</strong>에서 사용 중인 콘텐츠
                </>
              ) : (
                <>
                  실제 <strong>1/2/3차 병원</strong>에서 사용 중인 신뢰할 만한
                  콘텐츠
                </>
              )}
            </p>
            <ul>
              <li>
                유튜브 <strong>월 60만 명 이상</strong> 최다 사용자 검증
                <strong>(1.5억 뷰++)</strong>
              </li>
              <li>
                <strong>과학적인 이완요법,</strong> 네오클래식 수면음악 & 사운드
              </li>
              <li>
                BSMR, BSS, BES 등 <strong>단독 개발 기술력</strong> 보유,
                <br />
                <strong>총 441명 대상</strong> 수면의 질 평균{" "}
                <strong>약 200% 증가</strong>
              </li>
            </ul>
            <div className="btn">
              <button className="youtube-btn" onClick={goYoutube}>
                Youtube 바로가기 →
              </button>
            </div>
          </div>
          {/* </motion.div> */}
        </div>
        {/* second-container */}

        {/* third-container */}
        <div className="container">
          {/* <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{
            ease: "easeInOut",
            duration: 2,
            y: { duration: 1 },
          }}
        > */}
          <div className="content">
            <h2>
              <strong>유형별 맞춤 관리,</strong>
              <br />
              <strong>
                <span>AI 수면 케어 앱</span>
              </strong>
              을 통해
              <br />
              <strong>최적의 수면 습관</strong>을 만드세요.
            </h2>
            <p>
              지난 <strong>6년 간의</strong> 수면 빅데이터와 노하우를 담아,
            </p>
            <p>
              {isMobile ? (
                <>
                  AI 연구소와 브레이너 제이가 개발한{" "}
                  <strong>수면 케어 앱</strong>
                </>
              ) : (
                <>
                  AI 연구소와 브레이너 제이가 공동 개발한{" "}
                  <strong>수면 케어 앱</strong>
                </>
              )}
            </p>
            <p className="star-p">
              {isMobile ? (
                <></>
              ) : (
                <>
                  *정신과/신경과/임상심리학 교수들의 자문과 협력을 통해 안전성
                  검토
                </>
              )}
            </p>
            <ul>
              <li>
                <strong>AI 추천 알고리즘 - </strong>
                사용자 유형별 수면 및 기상 최적화
              </li>
              <li>
                <strong>일상 속 루틴 케어 -</strong> 숙면과 마음건강을 위한 습관
                관리
              </li>
              <li>
                <strong>'나'만의 커스톰 - </strong> 개인별 수면시간, 알람, 테마
                설정
              </li>
            </ul>
            <div className="btn">
              <button className="goToGooglebtn" onClick={goToGoogle}>
                Google Play →
              </button>
              <button className="goToAppStorebtn" onClick={goToAppstore}>
                App Store →
              </button>
            </div>
          </div>
          <div>
            <img src={fourth_img} alt="AI sleep care app illustration" />
          </div>
          {/* </motion.div> */}
        </div>
        {/* third-container */}

        {/* fourth-container */}
        <div className="container">
          {/* <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{
            ease: "easeInOut",
            duration: 2,
            y: { duration: 1 },
          }}
        > */}
          <div>
            <img src={fifth_img} alt="Sleep care solutions" />
          </div>
          <div className="content">
            <h2>
              <strong>과학적 근거</strong>와 <strong>임상 연구,</strong>
              <br />
              혁신적이고 체계적인
              <br />
              <span>토탈 수면 솔루션을 만나보세요.</span>
            </h2>
            <p>
              {isMobile ? (
                <>
                  <strong>국제적인 표준화 및 상용화</strong>를 위해
                </>
              ) : (
                <>
                  토탈 수면 솔루션의<strong> 국제적인 표준화 및 상용화</strong>
                  를 위해
                </>
              )}
            </p>
            <p>
              국내외 유수 연구기관과 <strong>총 8건의 임상연구 진행</strong>
            </p>
            <ul>
              <li>
                세계 최초<strong>4C[코칭-콘텐츠-케어-커뮤니티] </strong>토탈
                솔루션
              </li>
              <li>
                1차 치료 CBT-I를 보완하는{" "}
                <strong>근거기반 3세대 인터벤션</strong>
              </li>
              <li>
                수면분야 헬스케어<strong> 코치 양성 및 인증 제도</strong> 개발
              </li>
            </ul>
          </div>
          {/* </motion.div> */}
        </div>
        {/* fifth-container */}
      </motion.div>
    </section>
  );
}
